<template>
  <div>
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">History</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10">
      <div class="mt-10">
        <div class="vs-row sm:pb-16 pb-24">
          <div class="vs-col sm:w-1/2 w-full">
            <p class="text-2xl font-bold">History</p>
          </div>
          <div class="vs-col sm:w-1/2 w-full flex justify-end">
            <vs-button class="btn" color="danger" text-color="#fff" icon="delete">Remove All</vs-button>
          </div>
        </div>
      </div>
			<div class="vs-row flex mb-3" v-for="item in 3" :key="item">
				<div class="vs-col sm:w-1/3 w-full card-body">
					<img class="w-9 h-9 absolute ml-2" src="@/assets/images/discountpadge.svg" alt="">
					<span class="absolute pl-1 ml-2 text-white text-sm">25%</span>
					<span class="material-icons absolute fav-icon sm:mr-2">favorite</span>
					<img class="lg:h-36 sm:h-40 h-52 w-full sm:border-0 border sm:pr-3" src="@/assets/images/footer.jpg" alt="">   
				</div>
				<div class="vs-col sm:w-2/3 w-full border pl-5 pr-2 pb-2 pt-3">
					<div class="vs-row">
						<div class="vs-col sm:w-1/2 w-full">
							<p class="text-lg">Toys & Games</p>
						</div>
						<div class="vs-col sm:w-1/2 w-full flex justify-end">
							<vs-icon class="self-center" icon="try" size="20px" color="warning" />
							<a href="#" class="underline decoration-1 text-orange pl-1">Submit Review</a>
						</div>
					</div>
					<div class="vs-row">
						<p v-show="flag" class="text-blue">Quantity: 2</p>
						<div class="mt-1 vs-row flex">
              <div class="mr-2">
							<span class="text-blue">Price: </span><span class="text-orange ml-1">2.500 KWD </span>
              </div>
              <div class="flex">
              <vs-icon icon="check_circle_outline" class="self-center" size="20px" color="warning" />
              <p class="text-orange ml-1">paid</p>
              </div>
						</div>
						<div v-show="!flag" class="my-2">
							<span class="text-blue">1 Activity For 1 Children</span>
						</div>
						<div class="vs-row self-center text-base flex">
							<div v-if="!flag" class="vs-col sm:w-1/2 w-full justify-start">
								<p class="vs-col lg:w-1/2 w-full">Sun, 14 Feb, 2021</p>
								<p class="vs-col lg:w-1/2 w-full">09:00 Am - 03:00 Pm</p>
							</div>
							<div :class="{'w-full':flag, 'w-1/2':!flag }" class="vs-col sm:w-1/2 my-auto w-full flex justify-end">
								<span class="text-white">
									<vs-icon size="20px" class="self-center icon" icon="local_phone"></vs-icon>
									<vs-icon size="20px" class="self-center icon" icon="date_range"></vs-icon>
									<vs-icon size="20px" class="self-center icon" icon="refresh"></vs-icon>
									<vs-icon size="20px" class="self-center icon" icon="location_on"></vs-icon>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
	</div>
</template>

<script>
export default {
  data () {
		return {
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Profile',
          url: 'profile',
        },
        {
          title: 'History',
          active: true
        }
      ],
      flag: false,
		}
	}
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.btn {
  padding: 7px 20px;
  border-radius: 5px;
}
.card-body {
  position: relative;
}
.card-body .fav-icon {
  color:gray;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  top: 3%;
  right: 5%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.icon {
	background-color: #00DDCC;
	min-width: 25px;
	min-height: 25px;
	margin: 0px 4px;
	border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
	text-align: center;
	padding: 2px 0;
}
</style>